<template>
    <div>
        <div class="avatarUpdateContainer">
            <img :src="userInfo.avatar" class="avatar" v-if="userInfo.avatar">
            <img src="@/assets/imgs/default_avatar.png" class="avatar" v-else>

            <el-upload class="upload" :action="updateUrl" :data="updateData" :headers="updateHeader"
                :on-success="uploadAvatarSuccess" :file-list="fileList" :show-file-list="false">
                <el-button size="small" type="primary" plain>点击上传</el-button>
            </el-upload>
        </div>

        <div class="userInfoItemContainer">
            <div class="userInfoItemLabel">昵称</div>
            <div class="userInfoItemValue">{{ userInfo.nickName }}</div>
            <div class="userInfoItemOperate" @click="updateNickName">编辑资料</div>
        </div>
        <div class="userInfoItemContainer">
            <div class="userInfoItemLabel">手机号码</div>
            <div class="userInfoItemValue">{{ userInfo.phone }}</div>
            <div class="userInfoItemOperate" @click="phoneDialogVisible = true">更换绑定手机号</div>
        </div>

        <el-dialog title="验证原手机号" :visible.sync="phoneDialogVisible" width="320px" :close-on-click-modal="false">
            <el-form :model="phoneDataForm" :rules="phoneDataRole" ref="dataForm">
                <el-form-item prop="phone">
                    <el-input style="width: 265px;" v-model="phoneDataForm.phone" placeholder="原手机号"></el-input>
                </el-form-item>
                <el-form-item prop="code">
                    <el-input style="width: 165px;" v-model="phoneDataForm.code" placeholder="验证码"></el-input>
                    <span class="getCodeText" v-if="getCodeCountdown <= 0" @click="getCode">获取验证码</span>
                    <span class="getCodeText" v-else style="cursor:not-allowed;">{{ this.getCodeCountdown }}s</span>
                </el-form-item>
                <div class="loginBtn" @click="check">
                    验证
                </div>
            </el-form>
        </el-dialog>

        <el-dialog title="设置新手机号" :visible.sync="newPhoneDialogVisible" width="320px" :close-on-click-modal="false">
            <el-form :model="phoneDataForm" :rules="phoneDataRole" ref="dataForm">
                <el-form-item prop="phone">
                    <el-input style="width: 265px;" v-model="phoneDataForm.phone" placeholder="新手机号"></el-input>
                </el-form-item>
                <el-form-item prop="code">
                    <el-input style="width: 165px;" v-model="phoneDataForm.code" placeholder="验证码"></el-input>
                    <span class="getCodeText" v-if="getCodeCountdown <= 0" @click="getCode">获取验证码</span>
                    <span class="getCodeText" v-else style="cursor:not-allowed;">{{ this.getCodeCountdown }}s</span>
                </el-form-item>
                <div class="loginBtn" @click="updatePhone">
                    提交
                </div>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: 'ReplayClientAccountSetup',

    data() {
        return {
            phoneDataForm: {
                phone: "",
                code: "",
            },
            getCodeCountdown: 0,
            getCodeInterval: null,
            phoneDialogVisible: false,
            newPhoneDialogVisible: false,
            phoneDataRole: {
                code: [
                    {
                        required: true,
                        message: "验证码不能为空",
                        trigger: "blur",
                    }
                ],
                phone: [
                    {
                        required: true,
                        message: "手机号不能为空",
                        trigger: "blur",
                    }, {
                        pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                        message: "请填写正确的手机号码",
                        trigger: "blur",
                    }
                ],
            },
            fileList: [],

            userInfo: {
                id: "",
                avatar: "",
                avatarImgId: "",
                phone: "",
                nickName: "",
            },
        };
    },
    computed: {
        updateHeader() {
            return { token: this.$store.state.token }
        },
        updateUrl() {
            return this.$httpBack.uploadUrl;
        },
        updateData() {
            return { flag: 0 }
        }
    },

    mounted() {
        this.getUserInfo();
    },

    methods: {
        // 修改手机号
        updatePhone() {
            this.phoneDataForm.isNewPhone = 1;
            this.$httpBack.user.checkPhoneCode(this.phoneDataForm).then(res => {
                if (res.code == 0) {

                    let requestData = { phone: this.phoneDataForm.phone };
                    this.updateUserInfo(requestData);

                    this.phoneDataForm = {
                        phone: "",
                        code: ""
                    }
                }
            });
        },
        // 校验验证码
        check() {
            this.$httpBack.user.checkPhoneCode(this.phoneDataForm).then(res => {
                if (res.code == 0) {
                    this.phoneDataForm = {
                        phone: "",
                        code: ""
                    }
                    this.getCodeCountdown = 0;
                    clearInterval(this.getCodeInterval);
                    this.getCodeInterval = null;
                    this.phoneDialogVisible = false;
                    this.newPhoneDialogVisible = true;
                }
            })
        },
        // 获取验证码
        getCode() {
            if (this.getCodeCountdown > 0) {
                return;
            }

            var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            if (!this.phoneDataForm.phone || !reg_tel.test(this.phoneDataForm.phone)) {
                this.$message.error("请填写正确的手机号");
                return;
            }

            this.$httpBack.user.getPhoneCode({ phone: this.phoneDataForm.phone }).then(res => {
                if (res.code == 0) {
                    this.$message.success("获取成功，请留意短信");
                }
            });

            this.getCodeCountdown = 60;
            // 启动定时器
            this.getCodeInterval = setInterval(() => {
                // 创建定时器，每1秒执行一次
                this.getCodeCountdown -= 1;
                if (this.getCodeCountdown <= 0) {
                    clearInterval(this.getCodeInterval);
                    this.getCodeInterval = null;
                }
            }, 1000);
        },
        // 获取用户信息
        getUserInfo() {
            this.$httpBack.user.infoByClient({}).then(res => {
                if (res.code == 0 && res.data) {
                    this.userInfo = res.data;
                    this.$store.commit("saveUserInfo", res.data);
                }
            });
        },
        // 修改用户信息
        updateUserInfo(requestData) {
            requestData.id = this.userInfo.id;
            this.$httpBack.user.updateByClient(requestData).then(res => {
                if (res.code == 0 && res.data) {
                    this.userInfo = res.data;
                    this.$store.commit("saveUserInfo", res.data);
                    this.$emit("updateUserInfo", "");
                    this.newPhoneDialogVisible = false;
                }
            });
        },
        // 修改昵称
        updateNickName() {
            this.$prompt('请输入新的昵称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^[\u4e00-\u9fa5A-Za-z0-9]{1,20}$/,
                inputErrorMessage: '昵称只能包含中文、英文、数字'
            }).then(({ value }) => {
                let requestData = { nickName: value };
                this.updateUserInfo(requestData);
            });
        },
        // 上传头像
        uploadAvatarSuccess(response, file, fileList) {
            this.userInfo.avatar = response.data.url;
            this.userInfo.avatarImgId = response.data.id;
            let recordDate = { avatarImgId: this.userInfo.avatarImgId };
            this.updateUserInfo(recordDate);
        },
    },
};
</script>
<style scoped>
.loginBtn {
    width: 265px;
    height: 44px;
    background: #0077FF;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
}

.getCodeText {
    margin-left: 10px;
    width: 90px;
    font-size: 14px;
    color: #2E3742;
    cursor: pointer;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #DCE0E7;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    box-sizing: border-box;
}

.userInfoItemOperate {
    width: 102px;
    color: #0B7CFF;
    cursor: pointer;
}

.userInfoItemValue {
    width: 110px;
    color: #2E3742;
}

.userInfoItemLabel {
    width: 92px;
    color: #677583;
}

.userInfoItemContainer {
    margin-top: 26px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.upload {
    margin-left: 16px;
}

.avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
}

.avatarUpdateContainer {
    display: flex;
    align-items: center;
}
</style>